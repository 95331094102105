<template>
  <v-card class=" card-size" flat>
    <v-row class="pl-3 pr-6">
      
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">TOTAL ITINERARY</h3>
        <h5 class="light-gray-text">Analyse itineraries data in a variety of parameters.</h5>
        
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>

    </v-row>
    <v-card style="padding-left: 40px; padding-right: 20px; margin-top: 20px; margin-bottom: 20px ">
      <h5 class="card-h4-title px-3 mt-2 mb-4">ITINERARY ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          
          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider class="pb-6"></v-divider>

      <v-row >
        <v-col cols="4">
          <v-date-input
              v-model="dates"
              color="red"
              label="Select Date Range"
              max-width="500"
              multiple="range"
              variant="outlined"
              @change="fetchDataintoTable"
          ></v-date-input>
        </v-col>

        <v-col cols="4">
          <v-select
            v-model="filters.visitedStatus"
            :items="visitStatus"
            chips
            @change="fetchDataintoTable"
            clearable
            label="Visited Status"
            multiple
            placeholder="Select status"
            variant="outlined"
          >
          
        </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="filters.visitedStatus"
              :items="visitStatus"
              chips
              @change="fetchDataintoTable"
              clearable
              label="Officers"
              multiple
              placeholder="Select Officer"
              variant="outlined"
          >

          </v-select>
        </v-col>

        <!-- <v-col cols="4">
          <v-select
            label="CRM status"
            variant="outlined"
            placeholder="Select status"
            :items="CRMstatus"
            v-model="filters.crm_status"
            multiple
            chips
            @change="fetchDataintoTable"
            clearable
            label="CRM status"
            multiple
            placeholder="Select status"
            variant="outlined"
            @change="fetchData"
          >
          
        </v-select>
        </v-col> -->
      </v-row>
      <v-data-table
        v-model:page="page"
        :headers="headers"
        :items="processedItineraryList"
        :items-per-page="itemsPerPage"
        :server-items-length="totalItems"
        class="dataTable"
        item-key="id"
        @update:page="fetchDataintoTable"
    >
      

      <template v-slot:[`item.visited_status`]="{ item }">
<!--        <v-chip-->
<!--          :color="item.visited_status === 'VISITED' ? 'green' : 'red'"-->
<!--          dark-->
<!--          class="text-uppercase mt-3"-->
<!--          label-->
<!--          variant="outlined"-->
<!--          size="small"-->
<!--        >-->
<!--          {{ item.visited_status }}-->
<!--          <v-icon :icon="item.crm_status === 'COMPLETED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>-->
<!--        </v-chip>-->
<!--        <div v-if="item.visitedDate" class="red-text mb-3">-->
<!--          Date - {{ item.visitedDate }}-->
<!--        </div>-->

        <div style="padding: 10px">

          <div style="padding-bottom: 3px">
            <v-chip v-if="item.visited_status === 'Visited'" color="green" label style="height: 30px; width:140px; font-size: 12px;" class="bg-green-lighten-5" >
              VISITED
              <v-icon class="justify-end ml-12" >mdi-check</v-icon>
            </v-chip>

            <v-chip v-if="item.visited_status === 'Not Visited'" class="bg-red-lighten-4" color="red" label style="height: 30px; width:140px; font-size: 12px;  " >
              NOT VISITED
              <v-icon class="justify-end ml-6" >mdi-close</v-icon>
            </v-chip>

            <v-chip v-if="item.visited_status === 'Pending'" color="orange" label style="height: 30px; width:140px; font-size: 12px; background: lightgoldenrodyellow "  >
              PENDING
              <v-icon class="justify-end ml-11" >mdi-help-circle-outline</v-icon>
            </v-chip>
          </div>

          <div v-if="item.visitedDate" class="red-text mb-3">
            Date - {{ item.visitedDate }}
          </div>
        </div>

      </template>


      <template v-slot:[`item.actions`]="{ item }" >
              <v-row>
                  <v-btn 
                      class="mr-3 bg-blue-lighten-5 viewbtn"
                      variant="outlined"
                      color="blue"
                      icon
                      rounded="lg"
                      size="small"
                      width="80"
                      @click="viewItem(item)"
                  >
                    <v-icon>mdi-check-circle</v-icon>&nbsp; view
                  </v-btn>
                  <v-btn
                      class="mr-3 bg-green-lighten-5 "
                      color="green"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                      class="bg-red-lighten-5"
                      color="red"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
              </v-row>
            </template>
    </v-data-table>
    </v-card>       
      

      <!-- VIEW LEAD DIALOG -->
      <v-dialog v-model="view_leads_dialog" fullscreen>
        <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="view_leads_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">ITINERARY DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3" style="padding-bottom: 20px">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col class="secondC textCenter" cols="12" >
                
                <div class="image card-h4-title">
                  <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                </div>

                <div class="mt-6 ml-6">
                  <v-row>
                    <h3 class="dataCard">Agent name: </h3>
                    <h3 class="dataCard text-red">&nbsp; {{ selectedLead.assign_officer }}</h3>
                  </v-row>
                  <v-row class="mt-6">
                    <h5>Mobile : &nbsp; {{ selectedLead.mobile_number }}</h5>
                  </v-row>
                  <v-row class="mt-6">
                    <v-chip
                      variant="outlined"
                      :class="selectedLead.visited_status === 'NOT VISITED' ? 'chip1clrTwo' : 'chip1clr'"
                      :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'"
                      text-color="white"
                    >
                      <v-icon :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'">
                        mdi-check-circle-outline
                      </v-icon>&nbsp;
                      {{ selectedLead.visited_status }}
                    </v-chip>

                  </v-row>

                </div>
                <v-spacer></v-spacer>
                <div class="justify-right align-right ml-6">
                  <v-btn
                      variant="outlined"
                    class="bg-red-lighten-5 pa-0"
                    color="red"
                      width="50"
                      height="50"
                    @click="confirmDelete(selectedLead)"
                >
                  <v-icon color="red">mdi-trash-can</v-icon>
                </v-btn>
                </div>
                </v-col>
              </v-row>
              
              <v-divider class="my-3"></v-divider>

              <v-row class="pt-6">
                <v-col cols="6" class="px-6">
                  <v-card flat class="pt-2 card-hight-fixed">
                    <v-col cols="11">
                    <v-text-field
                      class="mb-2 "
                      label="Itinerary Title"
                      placeholder="Type title here"
                      readonly
                      v-model="selectedLead.title"
                      variant="outlined"
                    ></v-text-field>

                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="Province"
                          readonly
                          v-model="selectedLead.province"
                      ></v-select>

                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="District"
                          readonly
                          v-model="selectedLead.district"
                      ></v-select>

                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="DSD"
                          readonly
                          v-model="selectedLead.dsd"
                      ></v-select>

                      <v-card-text>
                        Itineraries Description
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card flat
                              width="100%"
                      >
                        <div class="d-flex justify-space-between pa-4 pb-0">
                          <v-btn-toggle
                              v-model="formatting"
                              divided
                              multiple
                              variant="outlined"
                          >
                            <v-btn>
                              <v-icon icon="mdi-format-italic"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-bold"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-underline"></v-icon>
                            </v-btn>

                            <v-btn>
                              <div class="d-flex align-center flex-column justify-center">
                                <v-icon icon="mdi-format-color-text"></v-icon>

                                <v-sheet
                                    color="purple"
                                    height="4"
                                    tile
                                    width="26"
                                ></v-sheet>
                              </div>
                            </v-btn>
                          </v-btn-toggle>

                          <v-btn-toggle
                              v-model="alignment"
                              divided
                              variant="outlined"
                          >
                            <v-btn>
                              <v-icon icon="mdi-format-align-center"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-align-left"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-align-right"></v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </div>

                        <v-sheet class="pa-4 text-center">
                          <v-textarea
                              v-model="selectedLead.description"
                              class="mb-2"
                              label="Itineraries Description"
                              placeholder="Write a description here..."
                              variant="outlined"

                          ></v-textarea>
                        </v-sheet>
                      </v-card>
                  </v-col>
                  </v-card>
                </v-col>
                <v-col cols="6" class="px-6">
                  <v-card flat class="pt-2 pb-8 card-hight-fixed">
                    <v-col cols="11">
                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="GND"
                          readonly
                          v-model="selectedLead.gnd"
                      ></v-select>

                    <v-text-field
                        variant="outlined"
                      class="mb-2"
                      label="Assign Officer"
                      readonly
                      v-model="selectedLead.assign_officer"
                    ></v-text-field>
                    
                    <v-text-field
                        variant="outlined"
                      label="Assign Date"
                      readonly
                      class="mb-2"
                      v-model="selectedLead.assign_date"
                    ></v-text-field>

                    <v-divider></v-divider>
                    <h5 class="px-3 py-3">SELECTED ITINERARY LIST</h5>
                    <h6 class="px-3 pb-3 light-gray-text">The following table shows all the leads related to your leads category & you can add only the leads you like.</h6>
                    <v-card class="ma-3 mb-8 pa-3" height="100%" style="overflow-x: hidden; width: fit-content">
                        <v-divider></v-divider>
                          <v-data-table
                              :headers="headers_two"
                              :items="items_two"
                              class="data-table"
                              :search="search"
                            >
                              <template v-slot:[`item.outlet_type`]="{ item }">
                                <span class="red-text">{{ item.outlet_type }}</span>
                              </template>

                            <template v-slot:[`item.actions`]="{ item }" >
                              <v-btn
                                  class="mr-6 bg-green-lighten-4"
                                  style="font-size: 9px"
                                  color="green"
                                  variant="outlined"
                                  size="small"
                                  @click="viewTableData(item)"
                              ><v-card-text v-if="items_two === item" class="red-text">{{ item.outlet_ttt }}</v-card-text>
                                <v-icon size="small" color="green" >mdi-pencil</v-icon> view
                              </v-btn>
                            </template>

                               <!-- Template for Status Column -->
                              <template v-slot:[`item.status`]="{ item }">
<!--                                <v-chip-->
<!--                                  :color="item.visited_status === 'VISITED' ? 'green' : 'red'"-->
<!--                                  dark-->
<!--                                  class="text-uppercase mt-3"-->
<!--                                  label-->
<!--                                  size="small"-->
<!--                                >-->
<!--                                  {{ item.visited_status }}-->
<!--                                  <v-icon :icon="item.visited_status === 'VISITED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>-->
<!--                                </v-chip>-->

                                <div style="padding: 3px">

                                  <div style="padding-bottom: 3px">
                                    <v-chip v-if="item.status === 'VISITED'" color="green" label style="height: 30px; width:auto; font-size: 12px;" class="bg-green-lighten-5" >
                                      VISITED
                                      <v-icon class="justify-end ml-3" >mdi-check</v-icon>
                                    </v-chip>

                                    <v-chip v-if="item.status === 'NOT VISITED'" class="bg-red-lighten-4" color="red" label style="height: 30px; width:auto; font-size: 12px;  " >
                                      NOT VISITED
                                      <v-icon class="justify-end ml-3" >mdi-close</v-icon>
                                    </v-chip>

                                    <v-chip v-if="item.status === 'PENDING'" color="orange" label style="height: 30px; width:auto; font-size: 12px; background: lightgoldenrodyellow "  >
                                      PENDING
                                      <v-icon class="justify-end ml-3" >mdi-help-circle-outline</v-icon>
                                    </v-chip>
                                  </div>
                                </div>
                              </template>

                          </v-data-table>
                    </v-card>
                  </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

        </v-card>
      </v-dialog>

      <!-- Edit LEAD DIALOG -->
    <v-dialog v-model="edit_leads_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="edit_leads_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
              <v-icon>mdi-view-dashboard
              </v-icon>
              <v-label class="ml-6">ITINERARY DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col class="secondC textCenter" cols="12" >

                  <div class="image card-h4-title">
                    <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                  </div>

                  <div class="mt-6 ml-6">
                    <v-row>
                      <h3 class="dataCard">Agent name: </h3>
                      <h3 class="dataCard text-red">&nbsp; {{ selectedLead.assign_officer }}</h3>
                    </v-row>
                    <v-row class="mt-6">
                      <h5>Mobile : &nbsp; {{ selectedLead.mobile_number }}</h5>
                    </v-row>
                    <v-row class="mt-6">
                      <v-chip
                          variant="outlined"
                          :class="selectedLead.visited_status === 'NOT VISITED' ? 'chip1clrTwo' : 'chip1clr'"
                          :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'"
                          text-color="white"
                      >
                        <v-icon :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'">
                          mdi-check-circle-outline
                        </v-icon>&nbsp;
                        {{ selectedLead.visited_status }}
                      </v-chip>

                    </v-row>

                  </div>
                  <v-spacer></v-spacer>
                  <div class="mt-3 justify-right align-right mr-5 ml-6">
                    <v-btn class="bg-red-lighten-5 mr-4" color="red" variant="outlined" @click="updateLead()">
                      UPDATE ITINERARIES DATA
                    </v-btn>
                    <v-btn
                        class="bg-grey-lighten-4 pa-0"
                        color="grey"
                        width="70"
                        variant="outlined"
                    >
                     CANCEL
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-divider class="my-3"></v-divider>

              <v-row class="pt-6">
                <v-col cols="6" class="px-6">
                  <v-card flat class="pt-2 card-hight-fixed">
                    <v-col cols="11">
                      <v-text-field
                          variant="outlined"
                          class="mb-2"
                          label="Itinerary Title"
                          placeholder="Type title here"
                          v-model="selectedLead.title"
                      ></v-text-field>


                      <v-select
                          class="mb-2"
                          label="Province"
                          variant="outlined"
                          placeholder="Select province"
                          v-model="selectedLead.province"
                          :items="provinces"
                          @update:modelValue="getAllDistrictAccProv"
                          item-text="label"
                          item-value="value"
                          :loading="mDistrictLoading"
                      ></v-select>

                      <v-select
                          class="mb-2"
                          label="District"
                          variant="outlined"
                          placeholder="Select district"
                          v-model="selectedLead.district"
                          :items="districts"
                          @update:modelValue="getAllDsd"
                          item-text="label"
                          item-value="value"
                          :loading="mDistrictLoading"
                          :disabled="isDisabledDistrictList"
                      ></v-select>

                      <v-select
                          class="mb-2"
                          label="Divisional"
                          variant="outlined"
                          placeholder="Select divisional"
                          v-model="selectedLead.dsd"
                          :items="dsdList"
                          item-text="label"
                          item-value="value"
                          @update:modelValue="getAllGnd"
                          :loading="mDSDLoading"
                          :disabled="isDisabledDSDLIST"
                      ></v-select>

                      <v-select
                          class="mb-2"
                          label="GND"
                          variant="outlined"
                          :items="gndList"
                          v-model="selectedLead.gnd"
                          :loading="mDSDLoading"
                          :disabled="isDisabledGNDList"
                          @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
                          placeholder="Select GND"

                      ></v-select>

                      <v-card-text>
                        Itineraries Description
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card flat
                              width="100%"
                      >
                        <div class="d-flex justify-space-between pa-4 pb-0">
                          <v-btn-toggle
                              v-model="formatting"
                              divided
                              multiple
                              variant="outlined"
                          >
                            <v-btn>
                              <v-icon icon="mdi-format-italic"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-bold"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-underline"></v-icon>
                            </v-btn>

                            <v-btn>
                              <div class="d-flex align-center flex-column justify-center">
                                <v-icon icon="mdi-format-color-text"></v-icon>

                                <v-sheet
                                    color="purple"
                                    height="4"
                                    tile
                                    width="26"
                                ></v-sheet>
                              </div>
                            </v-btn>
                          </v-btn-toggle>

                          <v-btn-toggle
                              v-model="alignment"
                              divided
                              variant="outlined"
                          >
                            <v-btn>
                              <v-icon icon="mdi-format-align-center"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-align-left"></v-icon>
                            </v-btn>

                            <v-btn>
                              <v-icon icon="mdi-format-align-right"></v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </div>

                        <v-sheet class="pa-4 text-center">
                          <v-textarea
                              v-model="selectedLead.description"
                              class="mb-2"
                              label="Itineraries Description"
                              placeholder="Write a description here..."
                              variant="outlined"

                          ></v-textarea>
                        </v-sheet>
                      </v-card>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="6" class="px-6">
                  <v-card flat class="pt-2 pb-8 card-hight-fixed">
                    <v-col cols="11">
                      <v-text-field
                          variant="outlined"
                          class="mb-2"
                          label="Assign Officer"
                          v-model="selectedLead.assign_officer"
                      ></v-text-field>

                      <v-text-field
                          variant="outlined"
                          label="Assign Date"
                          class="mb-2"
                          v-model="selectedLead.assign_date"
                      ></v-text-field>

                      <v-divider></v-divider>
                      <h5 class="px-3 py-3">SELECTED ITINERARY LIST</h5>
                      <h6 class="px-3 pb-3 light-gray-text">The following table shows all the leads related to your leads category & you can add only the leads you like.</h6>
                      <v-card class="ma-3 mb-8 pa-3" height="100%" style="overflow-x: hidden; width: fit-content">
                        <v-divider></v-divider>
                        <v-data-table
                            :headers="headers_two"
                            :items="items_two"
                            class="data-table"
                            :search="search"
                        >
                          <template v-slot:[`item.outlet_type`]="{ item }">
                            <span class="red-text">{{ item.outlet_type }}</span>
                          </template>

                          <template v-slot:[`item.actions`]="{ item }" >
                            <v-btn
                                class="mr-6 bg-green-lighten-4"
                                style="font-size: 9px"
                                color="green"
                                variant="outlined"
                                size="small"
                                @click="viewTableData(item)"
                            ><v-card-text v-if="items_two === item" class="red-text">{{ item.outlet_ttt }}</v-card-text>
                              <v-icon size="small" color="green" >mdi-pencil</v-icon> view
                            </v-btn>
                          </template>

                          <!-- Template for Status Column -->
                          <template v-slot:[`item.status`]="{ item }">
<!--                            <v-chip-->
<!--                                :color="item.visited_status === 'VISITED' ? 'green' : 'red'"-->
<!--                                dark-->
<!--                                class="text-uppercase mt-3"-->
<!--                                label-->
<!--                                size="small"-->
<!--                            >-->
<!--                              {{ item.visited_status }}-->
<!--                              <v-icon :icon="item.visited_status === 'VISITED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>-->
<!--                            </v-chip>-->

                            <div style="padding: 3px">

                              <div style="padding-bottom: 3px">
                                <v-chip v-if="item.status === 'VISITED'" color="green" label style="height: 30px; width:auto; font-size: 12px;" class="bg-green-lighten-5" >
                                  VISITED
                                  <v-icon class="justify-end ml-3" >mdi-check</v-icon>
                                </v-chip>

                                <v-chip v-if="item.status === 'NOT VISITED'" class="bg-red-lighten-4" color="red" label style="height: 30px; width:auto; font-size: 12px;  " >
                                  NOT VISITED
                                  <v-icon class="justify-end ml-3" >mdi-close</v-icon>
                                </v-chip>

                                <v-chip v-if="item.status === 'PENDING'" color="orange" label style="height: 30px; width:auto; font-size: 12px; background: lightgoldenrodyellow "  >
                                  PENDING
                                  <v-icon class="justify-end ml-3" >mdi-help-circle-outline</v-icon>
                                </v-chip>
                              </div>
                            </div>
                          </template>

                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

      </v-card>
    </v-dialog>

      <!-- Send reminder for not visited records -->
    <v-dialog v-model="reminderDialog" max-width="600" max-height="800" persistent>
      <v-card>
        <v-row dense class="rowClr mb-1 mt-3 mx-3">
          <v-col cols="12" md="9">
            <v-card-title class="headline">UPLOADED DETAILS</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="reminderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>


        <v-btn width="40vw" height="5vh" @click="toggleCard('imageCard')">UPLOAD IMAGES</v-btn>

        <!-- Image Card -->
        <v-card
            v-if="imageCard"
            style="margin-top: 8px; margin-bottom: 5px; margin-left: 15px; margin-right: 15px; padding: 10px; max-height: 400px; overflow-y: auto"
            variant="elevated"
        >
          <v-row dense>
            <v-col
                v-for="(image, index) in imageUrls"
                :key="index"
                cols="4"
            class="mb-2"
            >
            <v-card
                width="100%"
                height="150px"
                class="d-flex justify-center align-center mt-6"
            >
              <v-img
                  :src="image"
                  width="100%"
                  height="100%"
                  class="rounded"
                  alt="Uploaded Image"
                  contain
              ></v-img>
            </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-btn width="40vw" height="5vh" @click="toggleCard('checkList')">CHECKLIST</v-btn>


        <!-- Checklist Card -->
        <v-card
            v-if="checkList"
            style="margin: 20px; padding: 10px; max-height: 400px; overflow-y: auto;"
            variant="outlined"
        >
          <!-- Checklist Section -->
          <v-card class="mb-4">
            <v-row>
              <v-col cols="4" v-for="(section, index) in selectedSection" :key="index">
                <v-btn
                    color="red"
                    class="bg-red-lighten-4"
                    :variant="activeSection === section ? 'flat' : 'text'"
                    @click="setActiveSection(section)"
                >
                  {{ section }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <!-- Checklist Items -->
          <v-list v-if="activeSection">
            <v-list-item
                v-for="(item, index) in filteredChecklistItems"
                :key="index"
            >
              <v-card
                  class="mb-2 bg-red-lighten-5"
                  height="7vh"
                  style="font-size: 12px"
                  color="red"
              >
                <v-row align="center" class="pa-3">
                  <v-col cols="2">
                    <v-switch color="red"></v-switch>
                  </v-col>
                  <v-col cols="8">
                    <v-list-item-title class="mb-5">{{ item.item_name }}</v-list-item-title>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>
          </v-list>
        </v-card>

        <v-divider class="mb-2"></v-divider>
        <v-card-actions class="mr-1">
          <v-spacer></v-spacer>
          &nbsp; &nbsp;<v-btn @click="reminderDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteLeadDialog" max-width="500" max-height="500" persistent>
        <v-card class="pa-1">
          <v-row dense class="rowClr ma-1">
            <v-col cols="12" md="9">
              <v-card-title class="headline">DELETE LEAD</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="deleteLeadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this Lead ?</v-card-text>
          <v-card-text class="textspace">
            This lead will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp; 
            <v-btn @click="deleteLeadDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteLeadListEditDialog" max-width="500" max-height="500" persistent>
        <v-card class="pa-1">
          <v-row dense class="rowClr ma-1">
            <v-col cols="12" md="9">
              <v-card-title class="headline">DELETE LEAD</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="deleteLeadListEditDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this leads list ?</v-card-text>
          <v-card-text class="textspace">
            This data set will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="deleteLeadsListItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp; 
            <v-btn @click="deleteLeadListEditDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- sms dialog -->
      <v-dialog v-model="smsDialog" max-width="400" max-height="600" persistent>
        <v-card class="pa-1">
          <v-row dense class="rowClr mb-1 mt-3 mx-3">
            <v-col cols="12" md="9">
              <v-card-title class="headline">SEND MESSAGE</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="smsDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-text-field
            class="mb-1 mt-3 mx-3"
            label="Mobile"
            variant="outlined"
            placeholder="Mobile number"
            v-model="selectedItem.mobile_number"
            :rules="[v => !!v || 'Mobile number is required']"
            required
          ></v-text-field>

          <v-text-field
            class="mb-1 mx-3"
            label="Email"
            variant="outlined"
            placeholder="Email address"
            v-model="selectedItem.email"
            :rules="[v => !!v || 'Email is required']"
            required
          ></v-text-field>

          <v-textarea
              class="mb-1 mx-3"
              label="Note"
              variant="outlined"
              placeholder="Type your massage here..."
              v-model="selectedItem.note"
              :rules="[v => !!v || 'Note is required']"
              required
          ></v-textarea>

          <v-divider class="mb-3"></v-divider>
          <v-card-actions class="mr-1">
            <v-spacer></v-spacer>
            <v-btn color="red" @click="sendSMSSubmit" variant="outlined" class="bg-red-lighten-4">SEND</v-btn>&nbsp; &nbsp; 
            <v-btn @click="smsDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Snackbar to show notifications -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :top="snackAlert.snackBarTop"
      :right="snackAlert.snackBarRight"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
    </v-card>
  </template>
    
    <script>
    import axios from 'axios';
    import _ from 'lodash';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';
    import * as XLSX from "xlsx";
    export default {
      components: {

      },
      name: 'LeadsList',
      data() {
        return {

          selectedLeadEdit:{
            assign_officer: '',
            mobile_number: '',
            visited_status: '',
            title: '',
            province: '',
            district:'',
            dsd: '',
            gnd: '',
            description: '',
            assign_date: '',
          },

          activeSection: null,
          smsMobile: '',
          smsEmail: '',
          smsNote: '',

          imageCard: '',
          checkList: '',
          selectedTab: 'uploadedImages',

          selectedSection: [],
          imageUrls: [],
          checklistItems: [],
          checkListData:[],

          headers: [
            { text: 'Agent Name', value: 'assign_officer', sortable: true, title: 'Agent Name' },
            { text: 'Agent Visite Status', value: 'completionStatus', sortable: true, title: 'Agent Visite Status' },
            // { text: 'CRM Status', value: 'crm_status', sortable: true, title: 'CRM Status' },
            { text: 'Assign Date', value: 'assign_date', sortable: true, title: 'Assign Date' },
            { text: 'Action', value: 'actions', sortable: false, title: 'Action' }
          ],
          items: [],
          // items: [], // Items fetched from API
          totalItems: 5, // Set the correct total number of items
          page: 1,
          itemsPerPage: 10,
          filters: {
            startDate: '',
            endDate: '',
            visitedStatus: [],
            crm_status: []
          },
          visitStatus: ['VISITED', 'NOT VISITED'],
          CRMstatus: ['COMPLETED', 'NOT COMPLETED'],


          view_leads_dialog: false,

          edit_leads_dialog: false,

          deleteLeadDialog: false,
          deleteLeadListEditDialog: false,

          reminderDialog: false,

          selectedItem: {},

          smsDialog: false,
          dates: [],

          //addleads
          lead: {
            title: '',
            mainCategory: '',
            sub_category: '',
            province: '',
            district: '',
            description: '',
            dsd: '',
            gnd: '',
            assignAgent: '',
            assign_date: '',
          },
        
          file: null,

          headersTwoT: [
            { text: '', value: 'data-table-select', title: 'data-table-select' },
            { text: 'Sub Category', value: 'subCategory', sortable: true, title: 'Sub Category' },
            { text: 'Institute Name', value: 'instituteName', sortable: true, title: 'Institute Name' },
            { text: 'Address', value: 'address', sortable: true, title: 'Address' },
          ],

          // add leads
          headers_two: [
            // { text: '', value: 'data-table-select', title: 'data-table-select' },
            { text: 'Institute Name', value: 'institute_name', sortable: true, title: 'Institute Name' },
            { text: 'Address', value: 'address', sortable: true, title: 'Address' },
            { text: 'Status', value: 'status', sortable: true, title: 'Status' },
            { text: 'Visit Date', value: 'visited_date', sortable: true, title: 'Visit Date' },
            { text: 'Action', value: 'actions', sortable: false, title: 'Action' }
          ],
          // items_two: [],
          items_two: [],

          showLeadsCard: true, 

          // add leads bulk upload
          headers_dialog: [
            { text: 'Main Category', value: 'mainCategory', title: 'Main Category'},
            { text: 'Sub Category', value: 'sub_category', title: 'Sub Category'},
            { text: 'Province', value: 'province', title: 'Province'},
            { text: 'District', value: 'district', title: 'District'},
            { text: 'DSD', value: 'dsd', title: 'DSD'},
            { text: 'GND', value: 'gnd', title: 'GND'},
            { text: 'Assign Agent', value: 'assignAgent', title: 'Assign Agent'},
            { text: 'Assign Date', value: 'assign_date', title: 'Assign Date'},
            { text: 'Description', value: 'description', title: 'Description'},
            { text: 'Lead List', value: 'leadList', title: 'Lead List'},
          ],
          // items_dialog: [],
          items_dialog: [],
        
          //search
          search: '',

          // Dialog state
          leadsBulkUploadDialog: false,

          //select date
          assignDateMenu: false,

          selectedLeadId: null,
          selectedLead: null, // Store current lead details


          snackAlert: {
            snackbar: false, // Snackbar visibility
            snackColor: "", // Snackbar color
            snackMessage: "", // Snackbar message
            snackBarRight: false, // Snackbar position right
            snackBarTop: true,  // Snackbar position top
          },
          selectedCategory: null,
          selectAll: false,

          mainFilterLoading: false,
          subFilterLoading: false,

          mDistrictLoading: false,
          mDSDLoading: false,
          mGNDLoading: false,
          provinces: [],
          districts: [],
          dsdList: [],
          gndList: [],


          
          isDisabledProvinceList:false,
          isDisabledDistrictList:false,
          isDisabledDSDLIST:false,
          isDisabledGNDList:false,
          
          selectedDistrict:[],
          selectedProvince:"",
          selectedDSD:[],
          selectedGND:[],

          agents: [], 

          categories: [], 
          subCategories: [],
          allCategories:[],
          itineraryList:[],
        };
      },
      watch: {
    'selectedLead.lead_main_category': function(newValue) {
      // Example of using newValue
      console.log('Main Category changed to:', newValue);
      this.fetchLeads();
    },
    'selectedLead.lead_sub_category': function(newValue) {
      console.log('Sub Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.province': function(newValue) {
      console.log('Province changed to:', newValue);
      this.fetchLeads();
    },
    'lead.district': function(newValue) {
      console.log('District changed to:', newValue);
      this.fetchLeads();
    },
    'lead.dsd': function(newValue) {
      console.log('DSD changed to:', newValue);
      this.fetchLeads();
    },
    'lead.gnd': function(newValue) {
      console.log('GND changed to:', newValue);
      this.fetchLeads();
    }
  },

      computed: {
        processedItineraryList() {
          return this.itineraryList.map(item => {
            // Check if all items in itenerary_list are visited
            const allVisited = item.itenerary_list.every(listItem => listItem.status === 'VISITED');

            // Add a new field to the item to represent the completion status
            return {
              ...item,
              completionStatus: allVisited ? 'Completed' : 'Not Completed'
            };
          });
        },
        filteredItems() {
          let filtered = this.items;
                // Apply filters to items
          if (this.filters.startDate && this.filters.endDate) {
            //
          }

          if (this.filters.visitedStatus.length) {
            filtered = filtered.filter(item =>
              this.filters.visitedStatus.includes(item.visited_status)
            );
          }

          if (this.filters.crm_status.length) {
            filtered = filtered.filter(item =>
              this.filters.crm_status.includes(item.crm_status)
            );
          }

          return filtered;
        },
        filteredChecklistItems() {
          // Return only items matching the active section
          return this.checklistItems.filter(item => item.main_category === this.activeSection);
        }
        
      },

      created(){
        this.fetchDataintoTable();
        this.getItineraryList();
      },

      mounted() {
        this.fetchMainCategories();
        this.getAllProvinces();
        this.fetchAllAgents();
        this.getItineraryList();
        // this.loadLeadsList('All','province',null);
      },

  methods: {

    setActiveSection(section) {
      this.activeSection = section;

    },

    viewTableData(item){

      this.reminderDialog = true;
      this.checklistItems = item.itenerary_check_list;
      this.imageUrls = item.itenerary_list_image;
      // this.selectedSection = item.itenerary_check_list.main_category

      this.checklistItems.forEach(checklistItem => {
        if (checklistItem.main_category && !this.selectedSection.includes(checklistItem.main_category)) {
          this.selectedSection.push(checklistItem.main_category);
        }
      });


      console.log('ssssssss',this.checklistItems)
      console.log('rrrrrrrr',this.selectedSection)
    },


    getItineraryList(){

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userID", userId);
      

      axios.post('/mobileapi/getItinararyDetailsTotheWeb',formData,{
        headers: {
                'Authorization': `Bearer ${token}`
            }

        })
          .then(response => {
            
              this.itineraryList = response.data.data;
              console.log('huttoooowaren',this.itineraryList);

              this.statusCheck();

          })
          .catch(() => {
            console.log("fk2");
          });



    },

    statusCheck(){
      let hasNotVisited = false;

          // Iterate through each itinerary item
          for (const item of this.itineraryList) {
              // Check if the status is "not_visited"
              if (item.status === 'NOT VISITED"') {
                  hasNotVisited = true;
                  break; // Exit loop early if a not_visited item is found
              }

             
          }

          // View based on the presence of "not_visited" items
          if (hasNotVisited) {

              this.checkStaus = "Not Visited"
              console.log('There are items not visited.');
              // You can perform any actions needed for "not_visited" state
          } else {
              this.checkStaus = "Visited"
              console.log('All items are visited.');
              // You can perform any actions needed for "visited" state
          }
    },

    

    toggleCard(cardType) {

      if (cardType === 'imageCard') {
        this.imageCard = !this.imageCard;
      } else if (cardType === 'checkList') {
        this.checkList = !this.checkList;
      }
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
    const tableData = this.processedItineraryList.map((item, index) => ({
      '#': index + 1,
      'Agent Name': item.assign_officer,
      'Agent Visited Status': item.completionStatus,
      'Assign Date': item.assign_date,
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');


    XLSX.writeFile(workbook, 'leads_analysis.xlsx');
  },

  exportPDF() {
    const doc = new jsPDF();

    // Set the title for the document
    doc.text('Lead Analysis', 14, 10);

    // Prepare the data for the table
    const tableData = this.processedItineraryList.map((item, index) => [
      index + 1,
      item.assign_officer,
      item.completionStatus,
      item.assign_date,
    ]);

    // Define the columns for the table
    const tableColumns = ['#','Agent Name', 'Agent Visited Status', 'Assign Date'];

    // AutoTable plugin to create a table
    doc.autoTable({
      head: [tableColumns],
      body: tableData,
    });

    // Save the PDF with a name
    doc.save('leads_analysis.pdf');

  },

    toggleSelectAll() {
      this.filteredItems.forEach(item => {
        item.selected = this.selectAll;
      });
    },
    
    confirmDeleteList() {
      this.deleteLeadListEditDialog = true;
      // Perform delete operation here
    },

    deleteLeadsListItem(){
      // Then update card visibility
      this.showLeadsCard = false;
      this.deleteLeadListEditDialog = false;
    },

    // Call this method whenever mainCategory, subCategory, or location fields change
    async fetchLeads() {
      await this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
    },

    async loadLeadsList(mainCategory, subCategory) {
      try {
        // Determine the location_type and location values based on selected values
        let locationType = '';
        let locationValue = [];

        if (this.lead.gnd && this.lead.gnd.length > 0) {
          locationType = 'gnd';
          locationValue = [this.lead.gnd];
        } else if (this.lead.dsd && this.lead.dsd.length > 0) {
          locationType = 'dsd';
          locationValue = [this.lead.dsd];
        } else if (this.lead.district && this.lead.district.length > 0) {
          locationType = 'district';
          locationValue = [this.lead.district];
        } else if (this.lead.province && this.lead.province !== "") {
          locationType = 'province';
          locationValue = [this.lead.province];
        } 
        // Construct the payload
        const payload = {
          type: 'Spatial',
          country: 'SriLanka',
          district: locationType === 'district' ? locationValue : [],
          province: locationType === 'province' ? locationValue : [],
          dsd: locationType === 'dsd' ? locationValue : '',
          gnd: locationType === 'gnd' ? locationValue : '',
          location_type: locationType,
          main_filter: mainCategory,
          sub_filter: subCategory
        };
        

        // Make the API call
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getGISData', payload);

        console.log('table data for leads:', payload);
        if (response.data.http_status === 'success') {
          console.log('table data for leads two:', payload);
          // Map the response data to your table structure
          // this.filteredItems = response.data.data.map(item => ({
          //   subCategory: item.Type,
          //   instituteName: item.Name,
          //   address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`
          // }));

          this.items = response.data.data.map(item => ({
            subCategory: item.Type,
            instituteName: item.Name,
            address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`,
            selected: false // Add a default value for selection
          }));
        } else {
          console.error("Failed to load leads:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },


    fetchAllAgents() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 

        axios.post('/api/agentUser', {
            userId: userId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        })
        .then(response => {
            let sample = response.data.data;
            this.agents = sample.map(item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
            console.log('Agents:', this.agents);
        })
        .catch(error => {
            console.error('Error fetching Agents:', error);
        });
    },

    getAllProvinces() {
        this.districts = [];
        this.dsdList = [];
        this.gndList = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.provinces = response.data.data;
                this.mDistrictLoading = false;
              }
            })
            .catch(() => {
              this.mDistrictLoading = false;
            });
      },

      getAllDistrictAccProv(province) {
        console.log('Province selected:', province);
        this.districts = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const headers = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
            .then(response => {
              console.log('District API response:', response.data);
              if (response.data.http_status === 'success') {
                this.districts = response.data.data;
                console.log('District API response:', response.data);
                this.mDistrictLoading = false;
                this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
                console.log('main c:', this.selectedLead.lead_main_category);
                console.log('sub c:', this.selectedLead.lead_sub_category);
              }
            })
            .catch(error => {
              this.mDistrictLoading = false;
              console.error('Error fetching districts:', error);

            });
      },

      getAllDsd(district) {
        this.dsdList = [];
        this.mDSDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.dsdList = response.data.data;
                this.mDSDLoading = false;
                this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
                console.log('main c:', this.selectedLead.lead_main_category);
                console.log('sub c:', this.selectedLead.lead_sub_category);
              }
            })
            .catch(() => {
              this.mDSDLoading = false;
            });
      },

      getAllGnd(dsd) {
        this.gndList = [];
        this.mGNDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.gndList = response.data.data;
                this.mGNDLoading = false;
                this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
                console.log('main c:', this.selectedLead.lead_main_category);
                console.log('sub c:', this.selectedLead.lead_sub_category);
              }
            })
            .catch(() => {
              this.mGNDLoading = false;
            });
      },

    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');

          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
            .filter(val => val.spatial_type !== 'Point')
            .map(val => ({
              data: null,
              name: val.sub_filter,
              main_type: val.main_filter,
              sub_directory: val.data_model
            }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset){
      // if (filter === 'main'){
          this.isDisabledDistrictList = false;
          this.isDisabledDSDLIST = false;
          this.isDisabledGNDList = false;
          this.lead.province = null;
          this.lead.district = [];
          this.lead.dsd = [];
          this.lead.gnd = [];
          // if (selectedLead.lead_sub_category) {
          //   this.selectedLead.lead_sub_category="";
          // }
          // if (selectedLeadEdit.lead_sub_category) {
            this.selectedLeadEdit.lead_sub_category="";
          // }
          

          // Debugging: Check the input parameters
          // console.log('Filter:', filter);
          console.log('Dataset:', dataset);

          // Ensure that `this.allCategories` is correctly populated
          console.log('All Categories:', this.allCategories);
          
          let sub_filter = _.map(this.allCategories,function (o) {
              if (o.main_filter === dataset )
                  return o.sub_filter
          });

          sub_filter = _.reject(sub_filter, _.isNil);
          this.subCategories = sub_filter.sort();

          // Debugging: Verify the final result
           console.log('Sub Categories:', this.subCategories);
      
    },

        fetchDataintoTable(){
          // const userId = localStorage.getItem('userId');
          // const token = localStorage.getItem("token");
          //
          // const formData = new FormData();
          // formData.append("userId", userId);
          //
          // axios.post("/api/getLeadList", formData, {
          //   headers: {
          //       'Authorization': `Bearer ${token}`
          //   }
          //   }).then((response) => {
          //
          //     this.items = response.data.data.map(lead => {
          //       // Parse the assign_date and format it
          //       // const assignDate = new Date(lead.assign_date);
          //       // const formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;
          //
          //       let formattedDate = null;
          //       if (lead.assign_date) {
          //         const assignDate = new Date(lead.assign_date);
          //         formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;
          //       }
          //       this.filterDataByDateRange();
          //
          //       // Return a new object with the formatted date
          //       return {
          //         ...lead,
          //         assign_date: formattedDate
          //       };
          //
          //     }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at descending
          //             // this.items= response.data.data;
          //             // // this.totalItems = response.data.totalItems;
          //             console.log('lead list data',this.items);
          //           })
          //           .catch((error) => {
          //
          //
          //               console.log(error);
          //
          //           });
          },

        filterDataByDateRange() {
          if (this.dates.length === 2) { // Ensure there are start and end dates
            const [startDate, endDate] = this.dates.map(date => new Date(date).toISOString().split('T')[0]);
            
            this.items = this.allItems.filter(lead => {
              const assignDate = lead.assign_date ? new Date(lead.assign_date).toISOString().split('T')[0] : null;
              return assignDate && assignDate >= startDate && assignDate <= endDate;
            });
          } else {
            // If no date range is selected, show all items
            this.items = this.allItems;
          }
        },

        backPageRoute(){
          this.$router.back();
        },
        homeReload(){
          window.location.href = '/dashboard';
        },
        
        viewItem(item) {

          this.selectedLead = item; 
          this.view_leads_dialog = true;
          console.log('View item:', item);
          this.items_two = item.itenerary_list;
          console.log('View itemmmmmmmmm:', this.items_two);

        },
    editItem(item) {

      this.selectedLead = item;
      this.edit_leads_dialog = true;
      console.log('View item:', item);
      this.items_two = item.itenerary_list;
      console.log('View itemmmmmmmmm:', this.items_two);
    },

        sendSms(item) {
          this.selectedItem = { ...item };
          // this.smsMobile = this.selectedItem.mobile_number;
          // this.smsEmail = this.selectedItem.email;
          // this.smsNote = this.selectedItem.note;
          this.smsDialog = true;
        },
        sendSMSSubmit() {
          // Validate required fields
          if (!this.selectedItem.mobile_number || !this.selectedItem.email || !this.selectedItem.note) {
            // Show snackbar message
            this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
            this.snackAlert.snackColor = 'red';
            this.snackAlert.snackVisible = true;
            return; // Stop the function execution if validation fails
          }
          // Extract the required data
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');
          const sendSMSTo = {
            mobileNumber: this.selectedItem.mobile_number,
            email: this.selectedItem.email,
            note: this.selectedItem.note,
            userID: userId
          };
            // Log the reminder data
              console.log("SMS sent to:", this.selectedItem);

            // Make API call using axios
            axios.post('/api/message', sendSMSTo, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
              console.log('Success:', response.data);
              // Handle success response if needed
              this.smsDialog = false;
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error response if needed
            });
        },

        updateLead(){
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');

          // const selectedLeads = this.filteredItems.filter(item => item.selected);
          // const selectedLeadsJSON = JSON.stringify(selectedLeads);

          // Gather selected leads and map to the required format
          const selectedLeads = this.items
            .filter(item => item.selected)
            .map((item, index) => ({
              id: index + 1, // Assigning a unique ID (you may adjust this as needed)
              instiName: item.instituteName,
              address: item.address
            }));

          // Convert the mapped leads to a JSON string

          const selectedLeadsJSON = JSON.stringify(selectedLeads);

          const leadData = {
            leadsTitle: this.selectedLeadEdit.lead_title,
            leadId: this.selectedLeadEdit.id,
            mainCatagory: this.selectedLeadEdit.lead_main_category,
            subCatagory: this.selectedLeadEdit.lead_sub_category,
            province: this.selectedLeadEdit.province,
            district: this.selectedLead.district,
            // dsd: this.selectedLead.dsd,
            // gnd: this.selectedLeadEdit.gnd,
            dsd: Array.isArray(this.selectedLead.dsd) && this.selectedLead.dsd.length > 0 ? this.selectedLead.dsd.join(',') : '', 
            gnd: Array.isArray(this.selectedLeadEdit.gnd) && this.selectedLeadEdit.gnd.length > 0 ? this.selectedLeadEdit.gnd.join(',') : '', 
            assignAgent: this.selectedLeadEdit.agent_name,
            assignDate: new Date(this.selectedLeadEdit.assign_date).toISOString().split('T')[0],
            // leadsLists: //here json stringfy the lead list
            leadsLists: selectedLeadsJSON ,
            description: this.lead.description,
            userId,
          };

          axios.post('/api/addLeads', leadData, {
            // headers: {
            //   'Authorization': `Bearer ${token}`,
            //   'content-type': 'multipart/form-data',
            // },
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'content-type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
            .then(response => {
              console.log('Lead added successfully:', response.data);
              this.showSnackbar("Lead added successfully!", "success");
              // Handle successful addition (e.g., clear form, show success message)
            })
            .catch(error => {
              console.error('Error adding lead:', error);
              this.showSnackbar("Error!", "error");
              // Handle error (e.g., show error message)
            });
        },
      
        openReminderDialog(item) {
          this.selectedItem = { ...item };
          console.log("Opening reminder dialog for item:", item);
          this.reminderDialog = true;
        },
       
        sendReminder() {
          // Validate required fields
        if (
          !this.selectedItem.lead_title ||
          !this.selectedItem.institute_name ||
          !this.selectedItem.address ||
          !this.selectedItem.agent_name ||
          !this.selectedItem.mobile_number ||
          !this.selectedItem.email ||
          !this.selectedItem.note
        ) {
          // Show snackbar message
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
          this.snackAlert.snackColor = 'red';
          this.snackAlert.snackVisible = true;
          return; // Stop the function execution if validation fails
        }
          // Extract the required data
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');
          const reminderData = {
            title: this.selectedItem.lead_title,
            outletName: this.selectedItem.institute_name,
            outletAddress: this.selectedItem.address,
            assignee: this.selectedItem.agent_name,
            mobileNumber: this.selectedItem.mobile_number,
            email: this.selectedItem.email,
            note: this.selectedItem.note,
            userID: userId
          };

           // Log the reminder data
              console.log("Reminder sent to:", this.selectedItem);

            // Make API call using axios
            axios.post('/api/reminder', reminderData, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
              console.log('Success:', response.data);
              // Handle success response if needed
              this.reminderDialog = false;
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error response if needed
            });
        },

       // Opens the delete confirmation dialog and sets the selectedLeadId
        confirmDelete(item) {
          this.selectedLeadId = item.id; // Save the ID of the record to delete
          this.deleteLeadDialog = true; // Show the confirmation dialog
        },
        
        // Deletes the selected lead
        deleteItem() {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');
          const leadId = this.selectedLeadId;
          console.log('lead id', leadId);

          axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteLead`, {
            userId: userId,
            leadId: leadId,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchDataintoTable(); // Refresh the user list
            this.deleteLeadDialog = false; // Close the confirmation dialog
            console.log('Lead deleted successfully');
            this.showSnackbar("Lead delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting lead', error);
            this.deleteLeadDialog = false;
            this.fetchDataintoTable();
          });
        },
      }
    };
    </script>
    
    <style scoped>
    .v-card-title {
      font-weight: bold;
    }

    .card-hight-fixed{
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    .card-h4-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      color: gray;
    }
    .red-text {
      color: #b00d0d;
    }
    .red-light-text {
      background-color: #f1cccc;
      color: #b00d0d;
    }
    .red-white-text {
      background-color: #b00d0d;
      color: white;
    }
    .green-light-text {
      background-color: #aee8b1;
      color: #057b0a;
    }
    .green-text {
      color: #057b0a;
      background-color: #aee8b1;
    }
    .divider-margin-dv{
      margin-top: -20px;
    }
    .avatar-image {
      object-fit: cover;
      width: 100px;
      height: 100px;
      display: block;
    }

    .image {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      overflow: hidden; 
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
    }

    .dataCard{
      display: flex;
      justify-content: left;
    }

    .first_row{
      display: flex;
      justify-content: flex-end;

    }

    .secondC{
      display: flex;

    }

    .textCenter {
      text-align: center;
    }

    .chip1clr{
      background-color: #e4ffe5;
    }
    .chip1clrTwo{
      background-color: #f1cccc;
    }

    .chip2clr{
      background-color: #ffe7d4;
    }

    .rowClr{
      background-color: #d9d9d9;
    }
    .textspace{
      margin-top: -20px;
    }
    .viewbtn{
      font-size: 12px;
    }
    :deep(.dataTable) th, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
      text-align: center;

    }

    :deep(.dataTable) td, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;

    }

    :deep(.dataTable) {
      border: white;

    }

    :deep(.dataTable )  tr,  th:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */

    }
    :deep(.dataTable )  tr,  td:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */

    }


    .v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
      overflow-y: hidden;
    }


    </style>
    
<!-- Dashboard Component three - Line Chart -->

<template>
    <v-row style="margin-top: 10px">
        <v-col cols="4">
            <v-select
                label="Year"
                variant="outlined"
                placeholder="Select Year"
            ></v-select>
        </v-col>

        <v-col cols="8" style="display: flex; justify-content: end; margin-bottom: 5px">

          <v-card class="pa-2 flat" flat>
            <h5 class="text-blue">COUNT  97</h5>
            <h6 class="gray-text font-weight-bold"><v-icon size="14" class="box-icon-color-3 mr-2">mdi-checkbox-blank-outline</v-icon> VISITED ITINERARIES</h6>
            </v-card>

          <v-card class="pa-2 flat ml-5" flat>
            <h5 class="text-red d-flex justify-end" >COUNT  12</h5>
            <h6 class="gray-text font-weight-bold">NOT VISITED ITINERARIES<v-icon size="14" class="box-icon-color-4 ml-2">mdi-checkbox-blank-outline</v-icon></h6>
          </v-card>
        </v-col>
        </v-row>
    <v-card>

        <v-card-title class="width-of-card">
            <div class="hello" ref="chartdiv">
            </div>
        </v-card-title>
    </v-card>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';
am4core.useTheme(am4themes_animated);

export default {
  name: 'LineChartDashboardComponentThree',
  data() {
    return {
      visitedCount: 0,
      notVisitedCount: 0,
    };
  },
  mounted() {
    this.fetchChartDetails();
    

  },

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

  methods: {
    fetchChartDetails() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      
      const formData = new FormData();
      formData.append('userId', userId); // Replace with the actual userId

      axios.post('/api/getChartdetils', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.data.success) {
          this.visitedCount = response.data.visitedCount;
          this.notVisitedCount = response.data.notVisitedCount;
          this.createChart();
        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
    },

    createChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      // Sample data with backend values
      let data = [
        { status: "Visited", count: this.visitedCount ,color: am4core.color("#0D47A1") },
        { status: "Not Visited", count: this.notVisitedCount,color: am4core.color("red") }
      ];

      chart.data = data;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "status";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "count";
      series.dataFields.categoryX = "status";
      series.name = "Itineraries";
      series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";

      series.columns.template.adapter.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
      series.columns.template.adapter.add("stroke", (stroke, target) => {
        return target.dataItem.dataContext.color;
      });

      series.columns.template.fillOpacity = .8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;

      // Save chart instance
      this.chart = chart;
    }
  
  }
}
</script>


<style scoped>
.hello {
  width: 100%;
  height: 400px;
}
.width-of-card{
  width: 100%;
}
.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

.box-icon-color-1{
  background-color: rgb(27, 185, 25);
  color: rgb(27, 185, 25);
}

.yellow-text{
  color: #efb907 !important;
}

.box-icon-color-2{
  background-color: #efb907;
  color: #efb907;
}

.box-icon-color-3{
  background-color: blue;
  color: blue;
}

.box-icon-color-4{
  background-color: #b00d0d;
  color: #b00d0d;
}
</style>
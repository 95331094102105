<template>
  <v-card class="main-card">
    <v-row class="px-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">ALL OFFICERS LIST</h3>
        <h5 class="light-gray-text">Analyse all officers data.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="back()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

  <v-row class="sub-heading">
    <v-card class="sub-heading-card d-flex align-center">
      <v-col cols="3">
      <h5 class="card-h4-title px-3 mt-2 mb-4">ALL OFFICERS LIST
      </h5>
      </v-col>
      <v-col cols="9">
        <div class="tool-bar-style d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4 text-red mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="text-red">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="bg-green-lighten-4" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </v-col>
    </v-card>
  </v-row>

  <v-card class="main-card ma-0 pa-4 ">
    <v-row class="text-filed-row pa-0 ma-0">
      <v-col cols="12" md="12">
        <v-text-field
            class="text-black"
            label="Search"
            placeholder="Search"
            persistent-placeholder
            variant="outlined"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="table-row">
      <v-col cols="12" class="table-col">
        <v-data-table
            :headers="headers"
            :items="sales"
            :search="search"
            class="dataTable"
        >
          <template v-slot:[`item.states`]="{ item }" >
            <v-row >
              <v-chip
                  class="ma-2"
                  color="green"
                  variant="outlined"
                  label
                  v-if="item.states === 'ontrip'"
              >
                ON TRIP
                <v-icon icon="mdi-check" end class="ml-4"></v-icon>
              </v-chip>

              <v-chip
                  class="ma-2"
                  color="orange"
                  variant="outlined"
                  label
                  v-if="item.states === 'idling'"
              >
                IDLING
                <v-icon icon="mdi-close" end class="ml-5"></v-icon>
              </v-chip>

              <v-chip
                  class="ma-2"
                  color="red"
                  variant="outlined"
                  label
                  v-if="item.states === 'parking'"
              >
                PARKING
                <v-icon icon="mdi-check" end class="ml-3"></v-icon>
              </v-chip>
            </v-row>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
  </v-card>
</template>

<script>
export default {
  name: "AllAgents",
  data() {
    return {
      search: '',
      headers: [
        { text: 'Store Name', value: 'store', title: 'AGENT NAME' },
        { text: 'Address', value: 'address', title:'CURRENT ADDRESS'},
        { text: 'Agent Name', value: 'agent', title:'DISTRICT' },
        { text: 'Quantity Sold', value: 'quantity', title:'DSD' },
        { text: 'Total Collections', value: 'total', title: 'VE.NUMBER' },
        { text: 'Total Collections', value: 'states', title: 'STATES' },
      ],
      sales: [
        { store: 'Gamage Stores', address: 'No:22/1, Kumarakanda Road, Gonapinuwala', agent: 'Mr. Nehan Pannaseeha', reg: '1034', quantity: 'QUA-02', total: 'LKR. 106,995.00', states:'ontrip' },
        { store: 'Liyanage Enterprises', address: 'No : 12/3, Daisyvilla Avenue, Piliyandala', agent: 'Mr. Cherith Maitreya', reg: '1351', quantity: 'QUA-04', total: 'LKR. 427,000.00', states:'parking'},
        { store: 'Naveen Groceries', address: '264/1, Sri Jayawardenepura Kotte 10027', agent: 'Mr. Supun Kanakeratne', reg: '2024', quantity: 'QUA-03', total: 'LKR. 520,100.00', states:'ontrip'},
        { store: 'Jayasundara Stores', address: 'No: 23/4 Bandaranaike Mawatha, Nuggeda, 8090', agent: 'Mr. Kumarasri Aponsu', reg: '0784', quantity: 'QUA-05', total: 'LKR. 335,000.00',states:'idling' },
        { store: 'A.K.A. Wadood & Sons', address: '693/3A, Peradeniya Road, Mulgampola', agent: 'Mr. Mahendra Perera', reg: '5672', quantity: 'QUA-04', total: 'LKR. 165,200.00',states:'ontrip' },
      ],
    }
  },
  setup(){
    const toggleEnable = (item) => {
      item.trackingEnabled = !item.trackingEnabled;
    };

    return {
      toggleEnable,
    };
  },
  methods: {
    back(){
      this.$router.push('/live-tracking');
    }
  },
};
</script>

<style scoped>

.main-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
  display: flex;
}

.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.pdf-btn{
  width:60px;
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  margin-left: 30px;
}

.exl-btn{
  width:60px;
  background-color: rgb(82, 180, 109);
  border: 1px solid rgb(16, 69, 13);
}

.text-filed-row{
  margin-top: 0;
  height:6vh;
  padding: 20px;
}

.table-col{
  height: 80%;
}

.action-text{
  font-size: 9px;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
}
.view-btn{
  background-color: rgb(140, 182, 249);
  border: 1px solid rgb(76, 76, 228);
  border-radius: 5px;
  color: rgb(76, 76, 228);
  margin-right: 20px;
}
.del-btn{
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  margin-right: 20px;
}



</style>



